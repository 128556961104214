import { useQuery } from '@tanstack/react-query';

import type { CircularObjectType } from '@haaretz/s-types';
import type { UseQueryOptions } from '@tanstack/react-query';

/**
 * To be used with `useClientQuery` to indicate which query is being used.
 * When adding a new query, add a new key here
 */
export type ClientQueryCacheKey =
  | 'abTestList'
  | 'accessListByIp'
  | 'recheckAccessListByIp'
  | 'updateReadingHistory'
  | 'paywallEmailValidation'
  | 'personalList'
  | 'rainbowPersonalizedCampaigns'
  | 'rainbowPurchaseVisit'
  | 'rainbowToolsStatistics'
  | 'writerAlerts'
  | 'newsletterRegistration'
  | 'gamesRichTextElement';

interface ClientQueryProps<TData, TError = Error> {
  url: string;
  cacheKey: ClientQueryCacheKey;
  clientOptions?: UseQueryOptions<TData, TError, TData>;
  fetchOptions?: Omit<RequestInit, 'body'>;
  variables?: CircularObjectType<string, string | number | boolean | undefined>;
}

export default function useClientQuery<TData, TError = Error>({
  url,
  variables,
  clientOptions,
  fetchOptions,
  cacheKey,
}: ClientQueryProps<TData, TError>) {
  const isPostRequest = fetchOptions?.method === 'POST';

  const searchParams = isPostRequest
    ? null
    : new URLSearchParams(
        Object.entries(variables ?? {}).map(([key, value]) => [key, String(value)])
      );

  return useQuery<TData, TError, TData>(
    [
      'all',
      cacheKey,
      url,
      searchParams?.toString(),
      ...(variables === undefined ? [] : [variables]),
    ].filter(Boolean),
    async ({ signal }) => {
      const res = await fetch(`${url}${searchParams ? `?${searchParams.toString()}` : ''}`, {
        method: 'GET',
        credentials: 'include',
        ...fetchOptions,
        signal,
        ...(isPostRequest
          ? {
              body: JSON.stringify(variables || {}),
            }
          : {}),
      });
      const contentLength =
        (res.headers.has('Content-Length') && res.headers.get('Content-Length')) || '';
      const data = contentLength !== '0' ? await res.json() : null;

      if (data?.error) {
        console.error(data.error);

        return null;
      }

      const result = data satisfies Promise<TData>;

      return result;
    },
    {
      ...clientOptions,
      useErrorBoundary: false,
      enabled:
        typeof window !== 'undefined' &&
        (clientOptions?.enabled === undefined || clientOptions?.enabled),
    }
  );
}
