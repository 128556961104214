'use client';

import zIndex from '@haaretz/l-z-index.macro';
import HtzLink from '@haaretz/s-htz-link';
import useBi from '@haaretz/s-use-bi';
import * as React from 'react';
import s9 from 'style9';

import type { HtzLinkProps } from '@haaretz/s-htz-link';
import type { StyleExtend, InlineStyles } from '@haaretz/s-types';
import type { BiFunction } from '@haaretz/s-use-bi/types';

// `c` is short for `classNames`
const c = s9.create({
  base: {
    position: 'relative',
    zIndex: zIndex('above'),
  },
});

export type TeaserImageProps = {
  children?: React.ReactNode;
  /**
   * CSS declarations to be set as inline `style` on the
   * html element.
   *
   * By setting values of CSS Custom Properties based on
   * props or state in the consuming component (where
   * the value of `inlineStyle` is passed), `inlineStyle`
   * can be used as an API contract for setting dynamic
   * values to styles created with `style9.create()`:
   *
   * @example
   * ```ts
   * import s9 from 'style9';
   * const { styleExtend, } = s9.create({
   *   styleExtend: {
   *     color: 'var(--color-based-on-prop)',
   *   },
   * });
   *
   * function MyTeaserImage(props) {
   *   const inlineStyle = {
   *     '--color-based-on-prop': props.color,
   *   },
   *
   *   return (
   *    <TeaserImage
   *      styleExtend={[ styleExtend, ]}
   *      inlineStyle={inlineStyle}
   *    />
   *   );
   * }
   * ```
   */
  inlineStyle?: InlineStyles;
  /**
   * An array of `Style`s created by `style9.create()`.
   * WARNING: **_do not_** pass simple CSS-in-JS object.
   * The items in the array must be created with Style9's
   * `create` function.
   * The array can also hold falsy values to assist with
   * conditional inclusion of `Style`s:
   *
   * @example
   * ```ts
   * const { foo, bar, } = s9.create({ foo: { ... }, bar: { ... }, });
   * <TeaserImage styleExtend={[ someCondition && foo, bar, ]} />
   * ```
   */
  styleExtend?: StyleExtend;
  href: string;
  biData?: Parameters<BiFunction>['0'];
  onClick?: HtzLinkProps['onClick'];
};

export default function TeaserImage({
  children = null,
  styleExtend = [],
  href,
  onClick,
  biData,
}: TeaserImageProps) {
  const biAction = useBi();

  const clickHandler =
    biData || onClick
      ? function teaserImgeClickHandler(evt: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
          if (biData) biAction(biData);
          if (onClick) onClick(evt);
        }
      : undefined;
  return (
    <HtzLink
      href={href}
      tabIndex={-1}
      onClick={clickHandler}
      className={s9(c.base, ...styleExtend)}
    >
      {children}
    </HtzLink>
  );
}
