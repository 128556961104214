import * as Types from '@haaretz/s-fragments/Types';

import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { fetch } from '@haaretz/s-graphql-fetcher';
export type UpdateReadingListMutationVariables = Types.Exact<{
  input: Types.UpdateReadingListInput;
}>;


export type UpdateReadingListMutation = (
  { updateReadingList: (
    { status?: string | null, error?: string | null }
    & { __typename: 'UpdateReadingListResponse' }
  ) }
  & { __typename: 'Mutation' }
);


export const UpdateReadingListMutationDocument = `
    mutation UpdateReadingListMutation($input: UpdateReadingListInput!) {
  updateReadingList(input: $input) {
    __typename
    status
    error
  }
}
    `;
export const useUpdateReadingListMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateReadingListMutation, TError, UpdateReadingListMutationVariables, TContext>) =>
    useMutation<UpdateReadingListMutation, TError, UpdateReadingListMutationVariables, TContext>(
      ['UpdateReadingListMutation'],
      (variables?: UpdateReadingListMutationVariables) => fetch<UpdateReadingListMutation, UpdateReadingListMutationVariables>(UpdateReadingListMutationDocument, variables)(),
      options
    );
useUpdateReadingListMutation.getKey = () => ['UpdateReadingListMutation'];

useUpdateReadingListMutation.fetcher = (variables: UpdateReadingListMutationVariables, options?: RequestInit['headers']) => fetch<UpdateReadingListMutation, UpdateReadingListMutationVariables>(UpdateReadingListMutationDocument, variables, options);