'use client';

import React from 'react';

const ToggleContext = React.createContext<boolean>(false);

export default function useToggleContext() {
  const shoudPrevent = React.useContext(ToggleContext);

  return shoudPrevent;
}
export function ToggleContextProvider({ children }: React.PropsWithChildren) {
  return <ToggleContext.Provider value={true}>{children}</ToggleContext.Provider>;
}
