'use client';
/* eslint-disable import/order, import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars */
// 1. The import order of macros matter and they must be kept in this order
// 2. Since macros are transpiled out during build, it is okay for them
//   to be imported even when they are not used.
// -- color must always be first -- //
import color from '@haaretz/l-color.macro';
// ---
import fontStack from '@haaretz/l-font-stack.macro';
import radius from '@haaretz/l-radius.macro';
import space from '@haaretz/l-space.macro';
import zIndex from '@haaretz/l-z-index.macro';
// --- These return objects and must be spread or used inside `merge` --- //
import border from '@haaretz/l-border.macro';
import shadow from '@haaretz/l-shadow.macro';
import typesetter from '@haaretz/l-type.macro';
// --- These must come last --- //
import fork from '@haaretz/l-fork.macro';
import mq from '@haaretz/l-mq.macro';
import merge from '@haaretz/l-merge.macro';
/* eslint-enable import/order, import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars */

import * as React from 'react';
import s9 from 'style9';

import type { StyleExtend, InlineStyles } from '@haaretz/s-types';
// `c` is short for `classNames`
const c = s9.create({
  // This is essential so that the wrapper div covers the entire
  wrapper: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    top: 0,
    zIndex: zIndex('below'),
  },
});

export type RelevantDialogAttrs = Omit<
  React.ComponentPropsWithoutRef<'dialog'>,
  'aria-labelledby' | 'className' | 'style' | 'onToggle'
>;

export interface ModalProps extends RelevantDialogAttrs {
  /** The Children to be rendered inside `<Modal>` */
  children?: React.ReactNode;
  /**
   * The ID of the element (usually an h*) that is the title of the modal.
   *
   * In the **RARE** cases where labeling isn't required, manually pass `null`.
   */
  labelledBy: string | null;
  /** Controls whether the modal is open or closed */
  isOpen?: boolean;
  forbidClosing?: boolean;
  /**
   * A callback that is fired whenever the modal is opened or closed,
   * and takes an `isOpen` boolean argument indicating if the modal is open
   */
  onToggle?: (isOpen: boolean) => void;
  /**
   * A callback that's fired whenever a modal window is opened and passed
   * a reference to the modal's underlying `dialog` element.
   */
  onOpen?: (elem: HTMLDialogElement) => void;
  /**
   * CSS declarations to be set as inline `style` on the
   * html element.
   *
   * By setting values of CSS Custom Properties based on
   * props or state in the consuming component (where
   * the value of `inlineStyle` is passed), `inlineStyle`
   * can be used as an API contract for setting dynamic
   * values to styles created with `style9.create()`:
   *
   * @example
   * ```ts
   * import s9 from 'style9';
   * const { styleExtend, } = s9.create({
   *   styleExtend: {
   *     color: 'var(--color-based-on-prop)',
   *   },
   * });
   *
   * function MyModal(props) {
   *   const inlineStyle = {
   *     '--color-based-on-prop': props.color,
   *   },
   *
   *   return (
   *    <Modal
   *      styleExtend={[ styleExtend, ]}
   *      inlineStyle={inlineStyle}
   *    />
   *   );
   * }
   * ```
   */
  inlineStyle?: InlineStyles;
  /**
   * An array of `Style`s created by `style9.create()`.
   * WARNING: **_do not_** pass simple CSS-in-JS object.
   * The items in the array must be created with Style9's
   * `create` function.
   * The array can also hold falsy values to assist with
   * conditional inclusion of `Style`s:
   *
   * @example
   * ```ts
   * const { foo, bar, } = s9.create({ foo: { ... }, bar: { ... }, });
   * <Modal styleExtend={[ someCondition && foo, bar, ]} />
   * ```
   */
  styleExtend?: StyleExtend;
}

const Modal = React.forwardRef<HTMLDialogElement, ModalProps>(function Modal(
  {
    children,
    inlineStyle,
    isOpen = false,
    labelledBy,
    onClick,
    onToggle,
    onOpen,
    onClose: onCloseProp,
    forbidClosing = false,
    styleExtend = [],
    ...attrs
  }: ModalProps,
  ref
) {
  const currentOpenState = React.useRef(false);

  const _modalRef = React.useRef<HTMLDialogElement>(null);
  const modalRef = ref ?? _modalRef;

  React.useEffect(() => {
    const modalElem = 'current' in modalRef && modalRef.current;
    if (modalElem) {
      if (isOpen && !currentOpenState.current) {
        modalElem.showModal();
        currentOpenState.current = true;

        if (onOpen) onOpen(modalElem);
        if (onToggle) onToggle(true);
      } else if (!isOpen && currentOpenState.current) {
        modalElem.close();
        currentOpenState.current = false;
      }
    }
  }, [isOpen, onOpen, onToggle, modalRef]);

  const onClose = React.useCallback(
    (evt: React.SyntheticEvent<HTMLDialogElement>) => {
      if (forbidClosing) return;
      currentOpenState.current = false;
      if (onCloseProp) onCloseProp(evt);
      if (onToggle) onToggle(false);
    },
    [onCloseProp, onToggle, forbidClosing]
  );

  const handleClick = React.useCallback(
    (evt: React.MouseEvent<HTMLDialogElement>) => {
      // WHAT??? Close the modal when clicking on the modal???
      // That's weird, so let's explain:
      // The `dialog` element has a `div` element which wraps all
      // it's other content, so it is impossible to click on the
      // `dialog` element itself. However, There is also the
      // `::backdrop` pseudo-element, which is painted **outside**
      // and below the `dialog` element, so isn't covered by the div.
      // Since it's a pseudo-element and not a real one, any clicks on
      // it will actually be registered as clicking on the `dialog` itself.
      // We can use this to our advantage to close the modal whenever
      // a click is detected directly on the `dialog` element and not
      // one of it's descendants, as we _know_ the click came from "outside"
      // the modal's content area.
      // This is confusing at first, but it's a much better solution than
      // having to manually check if the click was outside the dialog's
      // bounding box.
      if (forbidClosing) return; // Prevent closing when allowClose is false
      if (evt.target === evt.currentTarget) evt.currentTarget.close();
      if (onClick) onClick(evt);
    },
    [onClick, forbidClosing]
  );

  const handleKeyDown = React.useCallback(
    (evt: React.KeyboardEvent<HTMLDialogElement>) => {
      if (forbidClosing && evt.key === 'Escape') {
        evt.stopPropagation(); // Prevent default behavior if allowClose is false
        evt.preventDefault();
      }
    },
    [forbidClosing]
  );

  return (
    // eslint-disable-next-line max-len
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events
    <dialog
      ref={modalRef}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      tabIndex={-1}
      onClose={onClose}
      {...attrs}
      aria-labelledby={labelledBy || undefined}
      className={s9(...styleExtend)}
      style={inlineStyle}
    >
      <div className={s9(c.wrapper)} />
      {children}
    </dialog>
  );
});

export default Modal;
