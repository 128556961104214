import { wrapWithEnvParam } from '../buildBaseImgSrc';

interface BuildBaseImgSrcsetParams {
  env: TEnv;
  src: string;
  widths: Array<number>;
  heights?: Array<number>;
  aspectRatio?: number;
}

export default function buildBaseImgSrcset({
  env,
  src,
  aspectRatio,
  widths,
  heights,
}: BuildBaseImgSrcsetParams) {
  // No need for srcset if there is only one width
  if (widths.length < 2) return undefined;

  const baseSrc = src.split('&')[0];
  const sortedWidth = [...widths].sort((a, b) => a - b);

  const srcSetObject: { [k in number]: string } = widths.reduce((acc, currWidth, index) => {
    const currentUrl = `${wrapWithEnvParam(
      `${baseSrc}&width=${currWidth}${`&height=${
        heights
          ? `&height=${heights[index]}`
          : `${(aspectRatio && Math.round(currWidth / aspectRatio)) || currWidth}`
      }`}`,
      env
    )} ${currWidth}w`;
    return { ...acc, [currWidth]: currentUrl };
  }, {});

  const srcSetString = sortedWidth.reduce((acc, currWidth, index) => {
    if (index === sortedWidth.length - 1) return `${acc}${srcSetObject[currWidth]}`;

    return `${acc}${srcSetObject[currWidth]}, `;
  }, '');

  return srcSetString;
}
