import getAspect from '../getAspect';

import type { ImageAspect } from '@haaretz/s-fragments/gql-types';
import type { ImageFragment } from '@haaretz/s-fragments/HTZ_image_Image';
import type { ImageCropFragment } from '@haaretz/s-fragments/ImageCrop';

export const aspectRatios = {
  regular: { height: 3, width: 3.9 }, // 1.3
  headline: { height: 9, width: 15.48 }, // 1.72
  landscape: { height: 9, width: 20.79 }, // 2.31
  square: { height: 1, width: 1 }, // 1
  vertical: { height: 20, width: 17 }, // 0.85
  wide: { height: 1, width: 3.18 }, // 3.18
} as const;

/**
 *
 * @public
 */
export default function getAspectData(
  aspect: ImageAspect,
  imgData: ImageFragment['files'][number]
) {
  const imageAspect = getAspect(aspect);
  const isCropped = imageAspect !== 'full';

  const aspectRatio = isCropped
    ? aspectRatios[imageAspect].width / aspectRatios[imageAspect].height
    : imgData.width / imgData.height;

  let aspectData: ImageCropFragment | null | undefined;

  if (isCropped) {
    aspectData = imgData.crops[imageAspect];

    if (!aspectData) {
      const croppedHeight = Math.round(imgData.width / aspectRatio);
      const shouldCropHeight = croppedHeight < imgData.height;

      const height = shouldCropHeight ? croppedHeight : imgData.height;
      const width = shouldCropHeight ? imgData.width : Math.round(imgData.height * aspectRatio);

      aspectData = {
        __typename: 'ImageCrop',
        height,
        width,
        x: 0,
        y: 0,
      };
    }
  }

  return { isCropped, aspectRatio, aspectData };
}
