import usePlatform from '@haaretz/s-atoms/platform';
import useUser from '@haaretz/s-atoms/user';
import { ConnectionTypes, SITE_PRODUCTS, site } from '@haaretz/s-consts';

import type { HtzUserType } from '@haaretz/s-types';

const DAY = 86400000;
const disabledUserTypes: HtzUserType[] = ['anonymous', 'registered'];
const disabledConnectionTypes: number[] = [ConnectionTypes.iphone, ConnectionTypes.android];

/**
 * Tests if the FOMO-tooltip should be displayed to current user.
 *
 * @returns true if user subscription expires in next 30 days, and is not an App subscription.
 */
export default function useDisplayFomoTooltip() {
  const user = useUser('cookieValue');
  const platform = usePlatform();

  let userSubscribedProduct = null;

  // Test for platform is not the mobile-appliction
  let shouldDisplay = platform !== 'app';

  // Test for paying user-type
  if (shouldDisplay) {
    shouldDisplay = !disabledUserTypes.includes(user.userType);
  }

  // Test if subscription is about to end in the next 30 days
  if (shouldDisplay) {
    const siteProducts = SITE_PRODUCTS[site];
    const userProducts = user.products || [];

    userSubscribedProduct = userProducts.find(
      prod => siteProducts.includes(prod.prodNum) && prod.status === 'SUBSCRIBED'
    );

    const today = new Date().setHours(0, 0, 0, 0);
    const futureEndDate = (userSubscribedProduct?.futureEndDate || -1) * 1000; // Convert unix-time to millis

    const daysUntilExpiration = (futureEndDate - today) / DAY;

    shouldDisplay = daysUntilExpiration >= 0 && daysUntilExpiration <= 30;
  }

  // Test if subscription is not from app-store
  if (shouldDisplay) {
    shouldDisplay = !disabledConnectionTypes.includes(userSubscribedProduct?.connectionType || -1);
  }

  return shouldDisplay;
}
