import type { ImageAspect } from '@haaretz/s-fragments/gql-types';
import type { ImageFragment } from '@haaretz/s-fragments/HTZ_image_Image';
import type { ImageCropFragment } from '@haaretz/s-fragments/ImageCrop';

export interface BuildBaseImgSrcArgs {
  imgData: ImageFragment['files'][number];
  contentId: ImageFragment['contentId'];
  width: number;
  height?: number;
  aspectRatio?: number;
  isCropped: boolean;
  aspectData?: ImageCropFragment | Omit<ImageCropFragment, '__typename'> | null;
  env: TEnv;
  /**
   * An object in the shape provided by the GraphQL API.
   */
  aspect: ImageAspect;
}

export function wrapWithEnvParam(url: string, env: TEnv) {
  return `${url}&cms${env}`;
}

export default function buildBaseImgSrc({
  aspect,
  aspectData,
  aspectRatio,
  contentId,
  env,
  height,
  imgData,
  isCropped,
  width,
}: BuildBaseImgSrcArgs) {
  const path = imgData.path;
  const subdomain = path.toLowerCase().endsWith('.gif') ? 'gif' : 'img';

  const precropParams = [];

  if (
    aspect &&
    aspect !== 'full' &&
    aspect !== 'fullimage' &&
    isCropped &&
    aspectData &&
    !!imgData.crops[aspect]
  ) {
    precropParams.push(aspectData.width);
    precropParams.push(aspectData.height);
    precropParams.push(`x${aspectData.x}`);
    precropParams.push(`y${aspectData.y}`);
  }
  if (path.startsWith('http')) {
    return path;
  }
  return wrapWithEnvParam(
    `https://${subdomain}.haarets.co.il/bs/${contentId}/${path}?${
      precropParams.length ? `precrop=${precropParams.join(',')}` : ''
    }&width=${width}&${`height=${
      height ? height : (aspectRatio && Math.round(width / aspectRatio)) || width
    }`}`,
    env
  );
}
