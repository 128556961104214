import * as Types from '@haaretz/s-fragments/Types';

import { useQuery, useInfiniteQuery, UseQueryOptions, UseInfiniteQueryOptions } from '@tanstack/react-query';
import { fetch } from '@haaretz/s-graphql-fetcher';
export type CheckArticlePersonalQueryVariables = Types.Exact<{
  input: Types.CheckArticleInput;
}>;


export type CheckArticlePersonalQuery = (
  { checkArticle?: (
    { exist: boolean, total: number, full: boolean }
    & { __typename: 'CheckArticleType' }
  ) | null }
  & { __typename: 'Query' }
);


export const CheckArticlePersonalQueryDocument = `
    query CheckArticlePersonalQuery($input: CheckArticleInput!) {
  checkArticle(input: $input) {
    __typename
    exist
    total
    full
  }
}
    `;
export const useCheckArticlePersonalQuery = <
      TData = CheckArticlePersonalQuery,
      TError = Error
    >(
      variables: CheckArticlePersonalQueryVariables,
      options?: UseQueryOptions<CheckArticlePersonalQuery, TError, TData>
    ) =>
    useQuery<CheckArticlePersonalQuery, TError, TData>(
      ['CheckArticlePersonalQuery', variables],
      fetch<CheckArticlePersonalQuery, CheckArticlePersonalQueryVariables>(CheckArticlePersonalQueryDocument, variables),
      options
    );
useCheckArticlePersonalQuery.document = CheckArticlePersonalQueryDocument;


useCheckArticlePersonalQuery.getKey = (variables: CheckArticlePersonalQueryVariables) => ['CheckArticlePersonalQuery', variables];
;

export const useInfiniteCheckArticlePersonalQuery = <
      TData = CheckArticlePersonalQuery,
      TError = Error
    >(
      pageParamKey: keyof CheckArticlePersonalQueryVariables,
      variables: CheckArticlePersonalQueryVariables,
      options?: UseInfiniteQueryOptions<CheckArticlePersonalQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<CheckArticlePersonalQuery, TError, TData>(
      ['CheckArticlePersonalQuery.infinite', variables],
      (metaData) => fetch<CheckArticlePersonalQuery, CheckArticlePersonalQueryVariables>(CheckArticlePersonalQueryDocument, {...variables, ...(metaData.pageParam ?? {})})(metaData),
      options
    )};


useInfiniteCheckArticlePersonalQuery.getKey = (variables: CheckArticlePersonalQueryVariables) => ['CheckArticlePersonalQuery.infinite', variables];
;

useCheckArticlePersonalQuery.fetcher = (variables: CheckArticlePersonalQueryVariables, options?: RequestInit['headers']) => fetch<CheckArticlePersonalQuery, CheckArticlePersonalQueryVariables>(CheckArticlePersonalQueryDocument, variables, options);