'use client';

import border from '@haaretz/l-border.macro';
import color from '@haaretz/l-color.macro';
import fontStack from '@haaretz/l-font-stack.macro';
import fork from '@haaretz/l-fork.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import radius from '@haaretz/l-radius.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import ClickArea from '@haaretz/s-click-area';
import FormfieldDescription from '@haaretz/s-formfield-description';
import Icon from '@haaretz/s-icon';
import React, { HTMLInputTypeAttribute, useEffect, useId, useImperativeHandle } from 'react';
import s9 from 'style9';

import type {
  InlineStyles,
  PolymorphicPropsWithoutRef,
  StyleExtend,
  Tuple2Union,
} from '@haaretz/s-types';

const borderWidth = '1px';
const transitionDuration = '0.2s';

const c = s9.create({
  base: {
    // This uses the "space toggle" technique.
    // See https://tinyurl.com/css-space-toggle for how this works.
    '--show-bg': 'var(--OFF)',

    '--inp-bgc-fallback': 'transparent',

    // Since off above is just a whitespace, the value we assign to
    // `--dflt-*-bgc` ends up being transparent. if we switch
    // `--show-bg` to `--ON`, the value will be `initial transparent`,
    // which effectively, is the same as `initial`, which basically
    // tells the var using `--dflt-*-bgc` to ignore it and use the
    // fallback value.
    // For a more thorough explanation see here:
    // https://tinyurl.com/space-toggle-logic
    '--dflt-inp-bgc': 'var(--show-bg) var(--inp-bgc-fallback)',
    '--dflt-lbl-bgc': 'var(--dflt-inp-bgc)',
    '--_inp-bgc': 'var(--dflt-inp-bgc, var(--inp-bgc, var(--inp-bgc-fallback)))',
    '--_lbl-bgc': 'var(--dflt-lbl-bgc, var(--lbl-bgc, var(--inp-bgc, var(--inp-bgc-fallback))))',

    '--_inp-brdr-rad': `var(--inp-brdr-rad, var(--inp-dflt-brdr-rad, ${radius('small', {
      singleSide: true,
    })}))`,
    '--_inp-pdng-blk': `var(--inp-pdng-blk, var(--inp-dflt-pdng-blk, ${fork({
      default: space(1.5),
      hdc: space(2.25),
    })}))`,
    '--_inp-pdng-inln': `var(--inp-pdng-inln, var(--inp-dflt-pdng-inln, ${space(2)}))`,
    '--_inp-pdng-inln-with-icn': `var(--inp-pdng-inln-with-icn, var(--inp-dflt-pdng-inln-with-icn, ${space(
      8
    )}))`,
    '--_inp-fs': `var(--inp-fs, var(--inp-dflt-fs, ${typesetter(0, { returnValue: 'fontSize' })}))`,
    '--_inp-lh': `var(--inp-lh, var(--inp-dflt-lh, ${typesetter(0, {
      returnValue: 'lineHeight',
    })}))`,

    '--_lbl-fs': `var(--lbl-fs, var(--lbl-dflt-fs, ${typesetter(-1, {
      returnValue: 'fontSize',
    })}))`,
    '--_lbl-lh': `var(--lbl-lh, var(--lbl-dflt-lh, ${typesetter(-1, {
      returnValue: 'lineHeight',
    })}))`,

    '--_lbl-rsd-fs': `var(--lbl-rsd-fs, var(--lbl-rsd-dflt-fs, ${typesetter(-2, {
      returnValue: 'fontSize',
    })}))`,
    '--_lbl-rsd-lh': `var(--lbl-rsd-lh, var(--lbl-rsd-dflt-lh, ${typesetter(-2, {
      returnValue: 'lineHeight',
    })}))`,
    '--_lbl-rsd-top': 'var(--lbl-rsd-top, var(--lbl-rsd-dflt-top, -100%))',
    '--_lbl-rsd-pdng-inln': `var(--lbl-rsd-pdng-inln, var(--lbl-rsd-dflt-pdng-inln, ${space(1)}))`,

    '--_lbl-pdng-inln-wth-strt-icn': `var(--lbl-pdng-inln-wth-strt-icn, var(--lbl-dflt-pdng-inln-wth-strt-icn, ${space(
      6
    )}))`,

    '--_rqrd-fs': `var(--rqrd-fs, var(--rqrd-dflt-fs, ${typesetter(0, {
      returnValue: 'fontSize',
    })}))`,
    '--rqrd-lh': `var(--rqrd-lh, var(--rqrd-dflt-lh, ${typesetter(0, {
      returnValue: 'lineHeight',
    })}))`,

    '--_rqrd-rsd-fs': `var(--rqrd-rsd-fs, var(--rqrd-rsd-dflt-fs, ${typesetter(-2, {
      returnValue: 'fontSize',
    })}))`,
    '--_rqrd-rsd-lh': `var(--rqrd-rsd-lh, var(--rqrd-rsd-dflt-lh, ${typesetter(-2, {
      returnValue: 'lineHeight',
    })}))`,

    '--_icn-sz': `var(--icn-sz, var(--icn-dflt-sz, ${space(4.5)}))`,
    '--_icn-inst-inln': `var(--icn-inst-inln, var(--icn-dflt-inst-inln, ${space(2)}))`,

    '--_shw-pswd-icn-sz': `var(--shw-pswd-icn-sz, var(--shw-pswd-icn-dflt-sz, ${space(7)}))`,

    '--_strt-part-wdth': `var(--strt-part-wdth, var(--strt-part-dflt-wdth, ${space(2)}))`,

    display: 'inline-flex',
    flexWrap: 'wrap',
    fontFamily: fork({
      default: fontStack('primary'),
      hdc: fontStack('secondary'),
    }),

    ...merge(
      mq({
        until: 'l',
        value: {
          '--icn-dflt-sz': space(4),
          '--shw-pswd-icn-dflt-sz': space(6),
        },
      }),
      mq({
        from: 'xl',
        until: 'xxl',
        value: {
          '--_shw-pswd-icn-dflt-sz': space(6.75),
        },
      }),
      mq({
        from: 'xl',
        value: {
          '--rqrd-dflt-fs': typesetter(-1, { returnValue: 'fontSize' }),
          '--rqrd-dflt-lh': typesetter(-1, { returnValue: 'lineHeight' }),
          '--rqrd-rsd-dflt-fs': typesetter(-3, { returnValue: 'fontSize' }),
          '--rqrd-rsd-dflt-lh': typesetter(-3, { returnValue: 'lineHeight' }),
        },
      }),
      mq({
        from: 'xxl',
        value: {
          '--inp-dflt-fs': typesetter(-1, { returnValue: 'fontSize' }),
          '--inp-dflt-lh': typesetter(-1, { returnValue: 'lineHeight' }),
          '--inp-dflt-pdng-inln-with-icn': space(9),
          '--lbl-dflt-fs': typesetter(-1, { returnValue: 'fontSize' }),
          '--lbl-dflt-lh': typesetter(-1, { returnValue: 'lineHeight' }),
          '--lbl-rsd-dflt-fs': typesetter(-3, { returnValue: 'fontSize' }),
          '--lbl-rsd-dflt-lh': typesetter(-3, { returnValue: 'lineHeight' }),
          '--lbl-dflt-pdng-inln-wth-strt-icn': space(7),
          '--shw-pswd-dflt-icn-sz': space(7),
          '--icn-dflt-sz': space(5.25),
          '--_shw-pswd-icn-dflt-sz': space(8),
        },
      })
    ),

    ':hover': {
      '--inp-brdr-c': 'var(--inp-hvr-brdr-c)',
    },
    ':focus-within': {
      '--inp-brdr-c': 'var(--inp-hvr-brdr-c)',
    },
  },
  opaque: {
    // This makes `--_inp-bgc` use the value of `--inp-bgc`
    // instead of `--default-inp-bgc`.
    // (and same for label)
    '--show-bg': 'var(--ON)',
  },
  borderTransition: {
    transitionProperty: 'border-color',
    transitionDuration,
    transitionTimingFunction: 'ease-in-out',
  },
  disabled: {
    cursor: 'no-drop',
  },
  input: {
    backgroundColor: 'var(--_inp-bgc)',
    color: 'var(--txt-c)',
    height: '100%',
    paddingBottom: 'var(--_inp-pdng-blk)',
    paddingTop: 'var(--_inp-pdng-blk)',
    paddingInlineStart: 'var(--_inp-pdng-inln)',
    paddingInlineEnd: 'var(--_inp-pdng-inln)',
    width: '100%',

    // Start part
    borderStartStartRadius: 'var(--_inp-brdr-rad)',
    borderEndStartRadius: 'var(--_inp-brdr-rad)',

    // End part
    borderStartEndRadius: 'var(--_inp-brdr-rad)',
    borderEndEndRadius: 'var(--_inp-brdr-rad)',

    fontSize: 'var(--_inp-fs)',
    lineHeight: 'var(--_inp-lh)',

    '::placeholder': {
      transitionProperty: 'color',
      transitionDuration,
      color: 'transparent',
    },
    ':focus': {
      outline: 'none',
      cursor: 'text',
    },
  },
  inputWithNoLabel: {
    '::placeholder': {
      color: 'var(--plchldr-c)',
    },
  },
  inputWithRaisedLabel: {
    '::placeholder': {
      transitionDelay: '0.15s',
      color: 'var(--plchldr-c)',
    },
  },
  inputEmail: {
    textAlign: fork({
      default: 'right',
      hdc: 'left',
    }),
  },
  inputWrapper: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    display: 'flex',
    left: 0,
    right: 0,
    pointerEvents: 'none',
    maxWidth: '100%',
  },
  inputWithStartIcon: {
    paddingInlineStart: 'var(--_inp-pdng-inln-with-icn)',
  },
  inputWithEndIcon: {
    paddingInlineEnd: 'var(--_inp-pdng-inln-with-icn)',
  },
  wrapper: {
    display: 'flex',
    width: '100%',
    position: 'relative',
  },
  icon: {
    fontSize: 'var(--_icn-sz)',
  },
  endIcon: {
    color: 'var(--end-icn-c)',
    alignItems: 'center',
    display: 'flex',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    insetInlineEnd: 'var(--_icn-inst-inln)',
    fontSize: 'var(--_icn-sz)',
  },
  showPassword: {
    alignItems: 'center',
    display: 'flex',
    position: 'absolute',
    insetInlineEnd: space(1),
  },
  showPasswordIcon: {
    fontSize: 'var(--_shw-pswd-icn-sz)',
  },
  endPart: {
    flexGrow: 1,
    borderStartEndRadius: 'var(--_inp-brdr-rad)',
    borderEndEndRadius: 'var(--_inp-brdr-rad)',
    ...border({
      width: borderWidth,
      color: 'var(--inp-brdr-c)',
      style: 'solid',
      spacing: 1,
      side: 'top',
    }),
    ...border({
      width: borderWidth,
      color: 'var(--inp-brdr-c)',
      style: 'solid',
      spacing: 1,
      side: 'bottom',
    }),
    ...border({
      width: borderWidth,
      color: 'var(--inp-brdr-c)',
      style: 'solid',
      spacing: 1,
      side: 'inline-end',
    }),
  },
  label: {
    backgroundColor: 'var(--_lbl-bgc)',
    color: 'var(--lbl-c)',
    display: 'inline-block',
    padding: 0,
    position: 'relative',
    top: 0,
    transitionProperty: 'all',
    transitionDuration,
    transitionTimingFunction: 'ease-in-out',
    fontSize: 'var(--_lbl-fs)',
    lineHeight: 'var(--_lbl-lh)',
  },
  labelWithStartIcon: {
    insetInlineStart: 'var(--_lbl-pdng-inln-wth-strt-icn)',
  },
  labelRaised: {
    color: 'var(--lbl-rsd-c)',
    fontWeight: 700,
    paddingLeft: 'var(--_lbl-rsd-pdng-inln)',
    paddingRight: 'var(--_lbl-rsd-pdng-inln)',
    transform: 'translateY(50%)',
    top: 'var(--_lbl-rsd-top)',
    insetInlineStart: 0,
    fontSize: 'var(--_lbl-rsd-fs)',
    lineHeight: 'var(--_lbl-rsd-lh)',
  },
  labelWrapper: {
    flex: '0 0 auto',
    display: 'flex',
    alignItems: 'center',
    maxWidth: `calc(100% - ${space(4)} * 2)`,
    width: 'auto',
    ...border({
      width: borderWidth,
      color: 'var(--inp-brdr-c)',
      style: 'solid',
      spacing: 1,
      side: 'block',
    }),
    transitionProperty: 'all',
    transitionDuration,
    transitionTimingFunction: 'ease-in-out',
  },
  labelWrapperRaised: {
    borderTopColor: 'transparent',
  },
  required: {
    color: 'var(--inp-rqrd-color)',
    marginInlineStart: space(1),
    transitionProperty: 'top, font-size, line-height',
    transitionDuration: `${transitionDuration}, ${transitionDuration}, ${transitionDuration}`,
    transitionTimingFunction: 'ease-in-out',
    fontSize: 'var(--_rqrd-fs)',
    lineHeight: 'var(--_rqrd-lh)',
  },
  requiredRaised: {
    fontSize: 'var(--_rqrd-rsd-fs)',
    lineHeight: 'var(--_rqrd-rsd-lh)',
  },
  startIcon: {
    alignItems: 'center',
    display: 'flex',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    insetInlineStart: 'var(--_icn-inst-inln)',
  },
  startPart: {
    width: 'var(--_strt-part-wdth)',
    height: '100%',
    borderStartStartRadius: 'var(--_inp-brdr-rad)',
    borderEndStartRadius: 'var(--_inp-brdr-rad)',
    ...border({
      width: borderWidth,
      color: 'var(--inp-brdr-c)',
      style: 'solid',
      spacing: 1,
      side: 'top',
    }),
    ...border({
      width: borderWidth,
      color: 'var(--inp-brdr-c)',
      style: 'solid',
      spacing: 1,
      side: 'bottom',
    }),
    ...border({
      width: borderWidth,
      color: 'var(--inp-brdr-c)',
      style: 'solid',
      spacing: 1,
      side: 'inline-start',
    }),
  },
  defaultState: {},
  disabledState: {
    opacity: 0.25,
    '--inp-brdr-c': 'var(--inp-hvr-brdr-c)',
  },
  isInvalid: {
    '--inp-brdr-c': `var(--inp-err-brdr-c, ${color('secondary900', { opacity: 0.2 })})`,
    '--inp-hvr-brdr-c': `var(--inp-err-hvr-brdr-c, ${color('secondary900')})`,
    '--lbl-rsd-c': `var(--lbl-err-c, ${color('secondary900')})`,
    '--end-icn-c': 'var(--lbl-rsd-c)',
  },
  defaultVariant: {
    // input colors
    '--txt-c': color('neutral1200'),
    '--inp-brdr-c': color('neutral1200', { opacity: 0.2 }),
    '--inp-hvr-brdr-c': color('neutral1200'),
    '--inp-rqrd-color': color('secondary900'),
    '--plchldr-c': color('neutral900'),

    // background-color - used only in opaque mode
    '--inp-bgc': color('neutral200'),

    // label colors
    // label background-color, falls back to `--inp-bgc` - used only in opaque mode
    // '--lbl-bgc': color('neutral200'),
    '--lbl-c': 'var(--txt-c)',
    '--lbl-rsd-c': color('neutral900'),
  },
  inverseVariant: {
    // input colors
    '--txt-c': color('neutral150'),
    '--plchldr-c': color('neutral400'),
    '--inp-brdr-c': color('neutral150', { opacity: 0.2 }),
    '--inp-hvr-brdr-c': color('neutral150'),
    '--inp-rqrd-color': color('secondary500'),

    // background-color - used only in opaque mode
    '--inp-bgc': color('neutral800'),

    // label colors
    // label background-color, falls back to `--inp-bgc` - used only in opaque mode
    // '--lbl-bgc': color('neutral200'),
    '--lbl-c': color('neutral200'),
    '--lbl-rsd-c': color('neutral200'),
    '--lbl-err-c': color('secondary500'),

    // Error state
    '--inp-err-brdr-c': color('secondary600', { opacity: 0.4 }),
    '--inp-err-hvr-brdr-c': color('secondary600'),
  },
  topSharp: {
    borderTopLeftRadius: radius('sharp'),
    borderTopRightRadius: radius('sharp'),
  },
  bottomSharp: {
    borderBottomLeftRadius: radius('sharp'),
    borderBottomRightRadius: radius('sharp'),
  },
  startSharp: {
    borderStartStartRadius: radius('sharp'),
    borderEndStartRadius: radius('sharp'),
    borderInlineStartWidth: `var(--inp-brdr-wdth, ${borderWidth})`,
  },
  endSharp: {
    borderStartEndRadius: radius('sharp'),
    borderEndEndRadius: radius('sharp'),
    borderInlineEndWidth: `var(--inp-brdr-wdth, ${borderWidth})`,
  },
  allSharp: {
    borderStartStartRadius: radius('sharp'),
    borderStartEndRadius: radius('sharp'),
    borderEndStartRadius: radius('sharp'),
    borderEndEndRadius: radius('sharp'),
  },
  topHideBorder: {
    borderTopWidth: 0,
  },
  bottomHideBorder: {
    borderBottomWidth: 0,
  },
  blockHideBorder: {
    borderBottomWidth: 0,
    borderTopWidth: 0,
  },
  startHideBorder: {
    borderInlineStartWidth: 0,
  },
  endHideBorder: {
    borderInlineEndWidth: 0,
  },
  inlineHideBorder: {
    borderInlineEndWidth: 0,
    borderInlineStartWidth: 0,
  },
  allHideBorder: {
    borderTopWidth: 0,
    borderInlineEndWidth: 0,
    borderBottomWidth: 0,
    borderInlineStartWidth: 0,
  },
  largeSize: {
    '--inp-dflt-brdr-rad': radius('xLarge', { singleSide: true }),
    '--inp-dflt-pdng-blk': space(3.5),
    '--inp-dflt-pdng-inln': space(3.5),
    '--inp-dflt-pdng-inln-with-icn': space(10),
    '--inp-dflt-fs': typesetter(1, { returnValue: 'fontSize' }),
    '--inp-dflt-lh': typesetter(1, { returnValue: 'lineHeight' }),

    '--lbl-dflt-fs': typesetter(1, { returnValue: 'fontSize' }),
    '--lbl-dflt-lh': typesetter(1, { returnValue: 'lineHeight' }),

    '--lbl-rsd-dflt-fs': typesetter(-1, { returnValue: 'fontSize' }),
    '--lbl-rsd-dflt-lh': typesetter(-1, { returnValue: 'lineHeight' }),
    '--lbl-rsd-dflt-top': '-90%',
    '--lbl-rsd-dflt-pdng-inln': space(4),

    '--lbl-dflt-pdng-inln-wth-strt-icn': space(8),

    '--icn-dflt-sz': space(7),
    '--icn-dflt-inst-inln': space(4),

    '--shw-pswd-icn-dflt-sz': space(8),

    '--strt-part-dflt-wdth': space(4),

    ...merge(
      mq({
        from: 'xxl',
        value: {
          '--inp-dflt-fs': typesetter(0, { returnValue: 'fontSize' }),
          '--inp-dflt-lh': typesetter(0, { returnValue: 'lineHeight' }),
          '--inp-dflt-pdng-inln-with-icn': space(11),
          '--lbl-dflt-fs': typesetter(0, { returnValue: 'fontSize' }),
          '--lbl-dflt-lh': typesetter(0, { returnValue: 'lineHeight' }),
          '--lbl-rsd-dflt-fs': typesetter(-1, { returnValue: 'fontSize' }),
          '--lbl-rsd-dflt-lh': typesetter(-1, { returnValue: 'lineHeight' }),
          '--lbl-dflt-pdng-inln-wth-strt-icn': space(9),
        },
      })
    ),
  },
});

const inputTypes = ['email', 'hidden', 'number', 'password', 'search', 'tel', 'text'] as const;

const DEFAULT_WRAPPER_ELEMENT = 'div';
type DefaultWrapperElement = typeof DEFAULT_WRAPPER_ELEMENT;

type OmittedAttributes = 'disabled' | 'children' | 'required' | 'style' | 'className' | 'size';
type InputTypes = Tuple2Union<typeof inputTypes>;
type InputOverrideAttributes =
  | 'value'
  | 'defaultValue'
  | 'type'
  | 'aria-describedby'
  | 'aria-live'
  | 'dir';

type Variant = 'default' | 'inverse';

interface SharedProps {
  /**
   * The input's functional state
   *
   * @defaultValue 'default'
   */
  state?: 'default' | 'disabled';
  /** Indicate the input is in an invalid state */
  isInvalid?: boolean;
  /**
   * CSS declarations to be set as inline `style` on the
   * html element.
   *
   * By setting values of CSS Custom Properties based on
   * props or state in the consuming component (where
   * the value of `inlineStyle` is passed), `inlineStyle`
   * can be used as an API contract for setting dynamic
   * values to styles created with `style9.create()`:
   *
   * @example
   * ```ts
   * import s9 from 'style9';
   *
   * const { styleExtend, } = s9.create({
   *   styleExtend: {
   *     color: 'var(--color-based-on-prop)',
   *   },
   * });
   *
   * function MyTextField(props) {
   *   const inlineStyle = {
   *     '--color-based-on-prop': props.color,
   *   },
   *
   *   return (
   *    <TextField
   *      styleExtend={[ styleExtend, ]}
   *      inlineStyle={inlineStyle}
   *    />
   *   );
   * }
   * ```
   */
  inlineStyle?: InlineStyles;
  /**
   * An array of `Style`s created by `style9.create()`.
   * WARNING: **_do not_** pass simple CSS-in-JS object.
   * The items in the array must be created with Style9's
   * `create` function.
   * The array can also hold falsy values to assist with
   * conditional inclusion of `Style`s:
   *
   * @example
   * ```ts
   * const { foo, bar, } = s9.create({ foo: { ... }, bar: { ... }, });
   * <TextField styleExtend={[ someCondition && foo, bar, ]} />
   * ```
   */
  styleExtend?: StyleExtend;
}

interface TextFieldWrapperOwnProps extends SharedProps {
  /**
   * Elements to render inside the wrapper, e.g., an input, a label,
   * description text
   */
  children: React.ReactNode;
  /**
   * Sets the basic visual appearance of the input.
   *
   * @defaultValue 'default'
   */
  variant?: Variant;
  /**
   * Give input a solid opaque background
   *
   * @defaultValue false
   */
  opaque?: boolean;
  /**
   * Sets the button's typography and inner-spacing (padding).
   *
   * @defaultValue 'regular'
   */
  size?: 'large' | 'regular';
}

type TextFieldWrapperProps<As extends React.ElementType = DefaultWrapperElement> =
  PolymorphicPropsWithoutRef<TextFieldWrapperOwnProps, As>;

export function TextFieldWrapper<As extends React.ElementType = DefaultWrapperElement>({
  as,
  children,
  inlineStyle,
  styleExtend = [],
  state = 'default',
  isInvalid,
  variant = 'default',
  opaque = false,
  size = 'regular',
  ...attrs
}: TextFieldWrapperProps<As>) {
  const Element: React.ElementType = as || DEFAULT_WRAPPER_ELEMENT;

  const variantClass: `${TextFieldWrapperProps['variant']}Variant` = `${variant}Variant`;
  const stateClass: `${TextFieldWrapperProps['state']}State` = `${state}State`;

  const hasSize = size !== 'regular';

  return (
    <Element
      className={s9(
        c.base,
        c[variantClass],
        c[stateClass],
        isInvalid && c.isInvalid,
        opaque && c.opaque,
        hasSize && c[`${size}Size`],
        ...styleExtend
      )}
      style={inlineStyle}
      {...attrs}
    >
      {children}
    </Element>
  );
}

interface TextFieldInputProps
  extends SharedProps,
    Omit<React.InputHTMLAttributes<HTMLInputElement>, OmittedAttributes> {
  // eslint-disable-next-line @typescript-eslint/brace-style
  /**
   * The `id` of the element describing the input
   */
  describedBy?: string;
  /**
   * An icon component to place at the beginning of the input.
   */
  startIcon?: React.ReactNode;
  /**
   * An icon component to place at the end of the input.
   */
  endIcon?: React.ReactNode;
  /**
   * Sets sharp corners (without border-radius) on two or more corners
   */
  sharp?: 'top' | 'bottom' | 'start' | 'end' | 'all';
  /**
   * The input's type attribue
   *
   * @defaultValue 'text'
   */
  type?: Extract<HTMLInputTypeAttribute, InputTypes>;
  /**
   * An input edge (or edges) on which the border is invisible
   * (e.g. for grouping with a button)
   */
  hideBorder?: 'top' | 'bottom' | 'start' | 'end' | 'inline' | 'block' | 'all';
  /**
   * The input's label
   */
  label?: string;
  /**
   * Indicates if the input is required
   *
   * @defaultValue false
   */
  required?: boolean;
}

export const TextFieldInput = React.forwardRef<HTMLInputElement, TextFieldInputProps>(
  function TextFieldinput(
    {
      autoFocus = false,
      describedBy,
      hideBorder,
      endIcon,
      inlineStyle = {},
      label,
      required = false,
      sharp,
      startIcon,
      state = 'default',
      isInvalid,
      styleExtend = [],
      type,
      ...attrs
    },
    ref
  ) {
    const [isAutoFilled, setIsAutoFilled] = React.useState(false);
    const [isLabelRaised, setIsLabelRaised] = React.useState(!!attrs.value || !!attrs.defaultValue);
    const [controlledInputType, setControlledInputType] =
      React.useState<TextFieldInputProps['type']>(type);
    const inputId = useId();
    const inputRef = React.useRef<HTMLInputElement>(null);

    const containerRef = React.useRef<HTMLDivElement>(null);

    useImperativeHandle(ref, () => inputRef.current as HTMLInputElement);

    const attrsOverride: Pick<TextFieldInputProps, InputOverrideAttributes> & {
      autofocus?: 'true';
    } = {};

    const didInitialLabelRaisedCheck = React.useRef(false);
    if (!didInitialLabelRaisedCheck.current) {
      didInitialLabelRaisedCheck.current = true;
      checkIsLabelRaised();
    }

    useEffect(() => {
      const selectors = [
        `input[id='${inputId}']:-autofill`,
        `input[id='${inputId}']:-webkit-autofill`,
      ].join(',');

      const autofilledElement = containerRef.current?.querySelector(selectors);

      setIsAutoFilled(!!autofilledElement);
    }, [inputId]);

    const isDisabled = state === 'disabled';

    if (attrs.value != null && !(attrs.onChange || attrs.readOnly)) {
      attrsOverride.defaultValue = attrs.defaultValue || attrs.value;
      attrsOverride.value = undefined;
    }

    if (autoFocus) {
      attrsOverride.autofocus = 'true';
    }

    const isPassword = type === 'password';
    const isEmail = type === 'email';
    const isHiddenType = type === 'hidden';

    const sharpClass: `${NonNullable<TextFieldInputProps['sharp']>}Sharp` | undefined = sharp
      ? `${sharp}Sharp`
      : undefined;

    const cancelBorderClass:
      | `${NonNullable<TextFieldInputProps['hideBorder']>}HideBorder`
      | undefined = hideBorder ? `${hideBorder}HideBorder` : undefined;

    const typeIsValid = controlledInputType && inputTypes.includes(controlledInputType);
    if (!typeIsValid) attrsOverride.type = 'text';
    else if (isEmail) attrsOverride.dir = 'ltr';

    if (describedBy) attrsOverride['aria-describedby'] = describedBy;

    function onBlur(event: React.FocusEvent<HTMLInputElement>): void {
      if (typeof attrs.onBlur === 'function') attrs.onBlur(event);

      checkIsLabelRaised();
    }

    function onFocus(event: React.FocusEvent<HTMLInputElement>): void {
      if (typeof attrs.onFocus === 'function') attrs.onFocus(event);

      setIsLabelRaised(true);
    }

    function checkIsLabelRaised(): void {
      const isFilled = inputRef.current != null ? !!inputRef.current.value : hasValueInAttrs();
      const isRaised = isFilled || isAutoFilled;
      setIsLabelRaised(isRaised);
    }

    function hasValueInAttrs(): boolean {
      return !!(
        attrs.value ||
        attrs.defaultValue ||
        attrsOverride.value ||
        attrsOverride.defaultValue
      );
    }

    const showPassword = React.useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        if (isPassword) {
          setControlledInputType(value => (value === 'password' ? 'text' : 'password'));
        }
      },
      [isPassword]
    );

    return (
      <div className={s9(c.wrapper, ...styleExtend)} style={inlineStyle} ref={ref}>
        <div className={s9(c.startIcon, c.borderTransition)}>{startIcon}</div>
        <div className={s9(c.endIcon, c.borderTransition)}>
          {isPassword ? (
            <ClickArea
              size="small"
              rippleSize="extraSmall"
              styleExtend={[c.showPassword]}
              onClick={showPassword}
              type="button"
            >
              <Icon
                icon={controlledInputType === 'password' ? 'show' : 'hide'}
                variant={isInvalid ? 'danger' : 'neutral'}
                a11yDescription={fork({ default: 'חשיפת סיסמה', hdc: 'Reveal password' })}
                a11yLabel={fork({ default: 'חשיפת סיסמה', hdc: 'Reveal password' })}
                styleExtend={[c.showPasswordIcon]}
              />
            </ClickArea>
          ) : isInvalid ? (
            <Icon icon="warn" />
          ) : (
            endIcon
          )}
        </div>
        <input
          className={s9(
            c.input,
            !label && c.inputWithNoLabel,
            !!startIcon && c.inputWithStartIcon,
            (isPassword || !!endIcon) && c.inputWithEndIcon,
            isEmail && c.inputEmail,
            isLabelRaised && c.inputWithRaisedLabel,
            !!sharpClass && c[sharpClass]
          )}
          type={controlledInputType}
          id={inputId}
          ref={inputRef}
          required={required}
          onBlur={onBlur}
          onFocus={onFocus}
          {...attrs}
          {...attrsOverride}
          disabled={isDisabled}
        />
        {!isHiddenType ? (
          <div className={s9(c.inputWrapper)}>
            <div
              className={s9(
                c.startPart,
                c.borderTransition,
                !!sharpClass && c[sharpClass],
                !!cancelBorderClass && c[cancelBorderClass]
              )}
            />
            <div
              className={s9(
                c.labelWrapper,
                isLabelRaised && c.labelWrapperRaised,
                !!cancelBorderClass && c[cancelBorderClass],
                c.borderTransition
              )}
            >
              {label ? (
                <label
                  htmlFor={inputId}
                  className={s9(
                    c.label,
                    !!startIcon && c.labelWithStartIcon,
                    isLabelRaised && c.labelRaised,
                    isDisabled && c.disabled
                  )}
                >
                  {label}
                  {required ? (
                    <span className={s9(c.required, isLabelRaised && c.requiredRaised)}>*</span>
                  ) : null}
                </label>
              ) : null}
            </div>
            <div
              className={s9(
                c.endPart,
                c.borderTransition,
                !!sharpClass && c[sharpClass],
                !!cancelBorderClass && c[cancelBorderClass]
              )}
            />
          </div>
        ) : null}
      </div>
    );
  }
);

export interface TextFieldProps
  extends Omit<TextFieldWrapperOwnProps, 'children'>,
    Omit<TextFieldInputProps, 'describedBy'> {
  description?: string;
}

const TextField = React.forwardRef<HTMLInputElement, TextFieldProps>(function TextField(
  {
    description,
    inlineStyle,
    styleExtend = [],
    state = 'default',
    isInvalid = false,
    variant = 'default',
    opaque = false,
    size = 'regular',
    ...attrs
  }: TextFieldProps,
  ref
) {
  const descriptionId = React.useId();
  const isHiddenType = attrs.type === 'hidden';

  return (
    <TextFieldWrapper
      styleExtend={styleExtend}
      inlineStyle={inlineStyle}
      state={state}
      isInvalid={isInvalid}
      variant={variant}
      opaque={opaque}
      size={size}
    >
      <TextFieldInput
        {...attrs}
        describedBy={description && descriptionId}
        state={state}
        isInvalid={isInvalid}
        ref={ref}
      />
      {description && !isHiddenType ? (
        <FormfieldDescription variant={variant} isInvalid={isInvalid} id={descriptionId}>
          {description}
        </FormfieldDescription>
      ) : null}
    </TextFieldWrapper>
  );
});

export default TextField;
