import { widthBpsInEm } from '@haaretz/l-theme-shared/lib/consts';

import type { MiscBpName, WidthBpName } from '@haaretz/l-theme-shared/types';
import type { RequireOne } from '@haaretz/s-types';

type OrientationBpName = Extract<MiscBpName, 'portrait' | 'landscape'>;
type FromMq = ` (${'min-width'}:${(typeof widthBpsInEm)[keyof typeof widthBpsInEm]}em) `;
type MiscMq = ` (${'orientation'}:${OrientationBpName}) `;
type Size = `${number}px` | `${'calc' | 'min' | 'max'}(${string})` | `${number}dvh` | `${number}vw`;
export type BaseSizeProps = { size: Size };
type OptionalSizeProps = RequireOne<{ from?: WidthBpName; misc?: OrientationBpName }>;
type FullSizeProps = BaseSizeProps & OptionalSizeProps;

export type SizesTypeArray = Array<FullSizeProps>;

export type SizesType = [...SizesTypeArray, BaseSizeProps] | SizesTypeArray | [BaseSizeProps];

export default function buildSizesString(sizes: SizesType) {
  const sortedArray = sizes.sort((a: SizesType[number], b: SizesType[number]) => {
    if (!('from' in b && b.from) && !('misc' in b && b.misc)) {
      return -1;
    }
    if (!('from' in a && a.from) && !('misc' in a && a.misc)) {
      return 1;
    }
    if ('from' in a && a.from) {
      if ('from' in b && b.from) return widthBpsInEm[b.from] - widthBpsInEm[a.from];
    }

    return 0;
  });

  const sizesString = sortedArray.reduce((acc, item, index) => {
    let from = undefined;
    let misc = undefined;
    const { size } = item;
    if ('from' in item) from = item.from;
    if ('misc' in item) misc = item.misc;

    const delimiter = sizes.length - 1 === index ? '' : ',';
    const mqCombiner = from && misc ? 'and' : '';

    let fromMq: '' | FromMq = '';
    let miscMq: '' | MiscMq = '';

    if (from) fromMq = ` (min-width:${widthBpsInEm[from]}em) `;
    if (misc) miscMq = ` (orientation:${misc}) `;

    const mq = `${fromMq}${mqCombiner}${miscMq}` as const;

    return `${acc}${mq}${size}${delimiter}` as const;
  }, '');

  return sizesString.trim();
}
