import type { ImageAspect } from '@haaretz/s-fragments/gql-types';

const aspectMap: Record<ImageAspect, Exclude<ImageAspect, 'fullimage'>> = {
  full: 'full',
  square: 'square',
  landscape: 'landscape',
  fullimage: 'full',
  wide: 'wide',
  regular: 'regular',
  vertical: 'vertical',
  headline: 'headline',
};

export default function getAspect(aspect: ImageAspect): Exclude<ImageAspect, 'fullimage'> {
  return aspectMap[aspect] || 'full';
}
