'use client';
import color from '@haaretz/l-color.macro';
import { useAnimationReducedAtom } from '@haaretz/s-atoms/animationReduced';
import discardFields from '@haaretz/s-common-utils/discardFields';
import Head from 'next/head';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import s9 from 'style9';

import type { ImageFragment } from '@haaretz/s-fragments/HTZ_image_Image';
import type { InlineStyles, StyleExtend, Tuple2Union } from '@haaretz/s-types';

const c = s9.create({
  base: {
    color: 'var(--altColor, transparent)',
  },
});

const allImgs = new Map<string, { src: string; priority: boolean }>();
let perfObserver: PerformanceObserver | undefined;

if (typeof window === 'undefined') {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (global as any).__NEXT_IMAGE_IMPORTED = true;
}

const VALID_LOADING_VALUES = ['lazy', 'eager', undefined] as const;
type LoadingValue = Tuple2Union<typeof VALID_LOADING_VALUES>;

type OnLoad = (img: HTMLImageElement) => void;

type ImgElementWithDataProp = HTMLImageElement & {
  'data-loaded-src': string | undefined;
};

export type ImageProps = Omit<
  JSX.IntrinsicElements['img'],
  'src' | 'ref' | 'alt' | 'loading' | 'onLoad' | 'height' | 'width'
> & {
  /**
   * CSS declarations to be set as inline `style` on the
   * html element.
   *
   * By setting values of CSS Custom Properties based on
   * props or state in the consuming component (where
   * the value of `inlineStyle` is passed), `inlineStyle`
   * can be used as an API contract for setting dynamic
   * values to styles created with `style9.create()`:
   *
   * @example
   * ```ts
   * import s9 from 'style9';
   * const { styleExtend, } = s9.create({
   *   styleExtend: {
   *     color: 'var(--color-based-on-prop)',
   *   },
   * });
   *
   * function MyButton(props) {
   *   const inlineStyle = {
   *     '--color-based-on-prop': props.color,
   *   },
   *
   *   return (
   *    <Button
   *      styleExtend={[ styleExtend, ]}
   *      inlineStyle={inlineStyle}
   *    />
   *   );
   * }
   * ```
   */
  inlineStyle?: InlineStyles;
  /**
   * An array of `Style`s created by `style9.create()`.
   * WARNING: **_do not_** pass simple CSS-in-JS object.
   * The items in the array must be created with Style9's
   * `create` function.
   * The array can also hold falsy values to assist with
   * conditional inclusion of `Style`s:
   *
   * @example
   * ```ts
   * const { foo, bar, } = s9.create({ foo: { ... }, bar: { ... }, });
   * <Button styleExtend={[ someCondition && foo, bar, ]} />
   * ```
   */
  styleExtend?: StyleExtend;
  /**
   * The URL of the image, must be an absolute external URL.
   */
  src: string;
  /**
   * Holds a text description of the image.
   */
  alt: string;
  /**
   * When true, the image will be considered high priority and preload.
   * Lazy loading is automatically disabled for images using priority.
   * Should only be used when the image is visible above the fold.
   * Defaults to false.
   */
  priority?: boolean;
  /**
   * The loading behavior of the image. Defaults to lazy.
   * When lazy, defer loading the image until it reaches a calculated distance from the viewport.
   * When eager, load the image immediately.
   */
  loading?: LoadingValue;
  /**
   * A callback function that is invoked once the image is completely loaded.
   * The callback function will be called with one argument, a reference
   * to the underlying <img> element.
   */
  onLoad?: OnLoad;
  height: number;
  width: number;
  imgData: ImageFragment['files'][number];
};

type ResetProps =
  | '__typename'
  | 'files'
  | 'type'
  | 'fbCaption'
  | 'fbCredit'
  | 'htzUrl'
  | 'playbackBehaviour'
  | 'publicUrl';

type ImageElementProps = Omit<ImageProps, 'alt'> & {
  isLazy: boolean;
  loading: LoadingValue;
  onLoadingCompleteRef: React.MutableRefObject<OnLoad | undefined>;
  setShowAltText: (b: boolean) => void;
} & { [key in ResetProps]?: never };

// eslint-disable-next-line @typescript-eslint/no-empty-function
let warnOnce = (_: string) => {};
if (process.env.NODE_ENV !== 'production') {
  const warnings = new Set<string>();
  warnOnce = (msg: string) => {
    if (!warnings.has(msg)) {
      console.warn(msg);
    }
    warnings.add(msg);
  };
}

// See https://stackoverflow.com/q/39777833/266535 for why we use this ref
// handler instead of the img's onLoad attribute.
function handleLoading(
  img: ImgElementWithDataProp,
  src: string,
  onLoadingCompleteRef: React.MutableRefObject<OnLoad | undefined>
) {
  if (!img || img['data-loaded-src'] === src) {
    return;
  }
  img['data-loaded-src'] = src;
  const p = 'decode' in img ? img.decode() : Promise.resolve();
  p.catch(() => {
    // eslint-disable-next-line no-console
    console.log(`Failed decoding ${img.src}`);
  }).then(() => {
    if (!img.parentNode) {
      // Exit early in case of race condition:
      // - onload() is called
      // - decode() is called but incomplete
      // - unmount is called
      // - decode() completes
      return;
    }
    if (onLoadingCompleteRef?.current) {
      onLoadingCompleteRef.current(img);
    }
    if (process.env.NODE_ENV !== 'production') {
      const heightModified = img.height.toString() !== img.getAttribute('height');
      const widthModified = img.width.toString() !== img.getAttribute('width');
      if ((heightModified && !widthModified) || (!heightModified && widthModified)) {
        warnOnce(
          `Image with src "${src}" has either width or height modified, but not the other. If you use CSS to change the size of your image, also include the styles 'width: "auto"' or 'height: "auto"' to maintain the aspect ratio.`
        );
      }
    }
  });
}

const ImageElement = ({
  src,
  sizes,
  srcSet,
  inlineStyle,
  isLazy,
  loading,
  width,
  height,
  styleExtend = [],
  onLoadingCompleteRef,
  setShowAltText,
  onLoad,
  onError,
  ...attrs
}: Omit<ImageElementProps, 'imgData'>) => {
  return (
    /* eslint-disable @next/next/no-img-element, jsx-a11y/alt-text */
    <img
      // NOTE: Ignore image content in chromatic snapshots
      //       because scaled rendering fucks them up
      data-chromatic="ignore"
      {...attrs}
      width={width}
      height={height}
      sizes={sizes}
      src={src}
      srcSet={srcSet}
      decoding="async"
      className={s9(c.base, ...styleExtend)}
      loading={isLazy ? 'lazy' : loading}
      style={{ ...inlineStyle }}
      ref={useCallback(
        (img: ImgElementWithDataProp | null) => {
          if (!img) {
            return;
          }
          if (onError) {
            // If the image has an error before react hydrates, then the error is lost.
            // The workaround is to wait until the image is mounted which is after hydration,
            // then we set the src again to trigger the error handler (if there was an error).
            // eslint-disable-next-line no-self-assign
            img.src = img.src;
          }
          if (process.env.NODE_ENV !== 'production') {
            if (!src) {
              console.error('Image is missing required "src" property:', img);
            }
            if (img.getAttribute('alt') === null) {
              console.error(
                'Image is missing required "alt" property. Please add Alternative Text to describe the image for screen readers and search engines.'
              );
            }
          }
          if (img.complete) {
            handleLoading(img, src, onLoadingCompleteRef);
          }
        },
        [src, onLoadingCompleteRef, onError]
      )}
      onError={event => {
        // if the real image fails to load, this will ensure "alt" is visible
        setShowAltText(true);
        if (onError) {
          onError(event);
        }
      }}
    />
    /* eslint-enable @next/next/no-img-element, jsx-a11y/alt-text  */
  );
};

const resetArgs: ResetProps[] = [
  '__typename',
  'files',
  'type',
  'fbCaption',
  'fbCredit',
  'htzUrl',
  'playbackBehaviour',
  'publicUrl',
];

export default function Image({
  src,
  srcSet,
  sizes,
  priority = false,
  loading,
  width,
  height,
  inlineStyle,
  styleExtend = [],
  imgData,
  onLoad,
  ...all
}: ImageProps) {
  const rest: Partial<ImageProps> = all;

  const isLazy = !priority && (loading === 'lazy' || typeof loading === 'undefined');

  const [showAltText, setShowAltText] = useState(false);
  const [animationReduced] = useAnimationReducedAtom();
  const [overrideSrcSet, setOverrideSrcSet] = React.useState(srcSet);

  const subdomain = imgData.path.toLowerCase().endsWith('.gif') ? 'gif' : 'img';
  const isAnimationReduced = subdomain === 'gif' && animationReduced;

  React.useEffect(() => {
    if (srcSet?.includes('.gif') && isAnimationReduced) {
      const imgSrcSet = srcSet?.replaceAll(/\.gif\??/g, '.gif?frame=1');
      setOverrideSrcSet(imgSrcSet);
    } else if (srcSet !== overrideSrcSet) {
      setOverrideSrcSet(srcSet);
    }
  }, [isAnimationReduced, overrideSrcSet, srcSet]);

  if (process.env.NODE_ENV !== 'production') {
    if (!VALID_LOADING_VALUES.includes(loading)) {
      console.error(
        new Error(
          `Image with src "${src}" has invalid "loading" property. Provided "${loading}" should be one of ${VALID_LOADING_VALUES.map(
            String
          ).join(',')}.`
        )
      );
    }

    if (typeof width === 'undefined') {
      console.error(new Error(`Image with src "${src}" is missing required "width" property.`));
    } else if (isNaN(width)) {
      console.error(
        new Error(
          `Image with src "${src}" has invalid "width" property. Expected a numeric value in pixels but received "${width}".`
        )
      );
    }

    if (typeof height === 'undefined') {
      console.error(new Error(`Image with src "${src}" is missing required "height" property.`));
    } else if (isNaN(height)) {
      console.error(
        new Error(
          `Image with src "${src}" has invalid "height" property. Expected a numeric value in pixels but received "${height}".`
        )
      );
    }

    if (priority && loading === 'lazy') {
      console.error(
        new Error(
          `Image with src "${src}" has both "priority" and "loading='lazy'" properties. Only one should be used.`
        )
      );
    }

    if ('ref' in rest) {
      warnOnce(
        `Image with src "${src}" is using unsupported "ref" property. Consider using the "onLoad" property instead.`
      );
    }

    if (typeof window !== 'undefined' && !perfObserver && window.PerformanceObserver) {
      perfObserver = new PerformanceObserver(entryList => {
        for (const entry of entryList.getEntries()) {
          // @ts-expect-error - missing "LargestContentfulPaint" class with "element" prop
          const imgSrc = entry?.element?.src || '';
          const lcpImage = allImgs.get(imgSrc);
          if (lcpImage && !lcpImage.priority) {
            // https://web.dev/lcp/#measure-lcp-in-javascript
            warnOnce(
              `Image with src "${lcpImage.src}" was detected as the Largest Contentful Paint (LCP). Please add the "priority" property if this image is above the fold.` +
                '\nRead more: https://nextjs.org/docs/api-reference/next/image#priority'
            );
          }
        }
      });
      try {
        perfObserver.observe({
          type: 'largest-contentful-paint',
          buffered: true,
        });
      } catch (err) {
        // Log error but don't crash the app
        console.error(err);
      }
    }
  }

  if (process.env.NODE_ENV !== 'production' && typeof window !== 'undefined') {
    allImgs.set(src, { src, priority });
  }

  let imageSrcSetPropName = 'imagesrcset';
  let imageSizesPropName = 'imagesizes';
  if (process.env.__NEXT_REACT_ROOT || process.env.NODE_ENV === 'test') {
    imageSrcSetPropName = 'imageSrcSet';
    imageSizesPropName = 'imageSizes';
  }
  const linkProps: React.DetailedHTMLProps<
    React.LinkHTMLAttributes<HTMLLinkElement>,
    HTMLLinkElement
  > = {
    [imageSrcSetPropName]: overrideSrcSet,
    [imageSizesPropName]: sizes,
    crossOrigin: rest.crossOrigin,
  };

  const onLoadingCompleteRef = useRef(onLoad);

  useEffect(() => {
    onLoadingCompleteRef.current = onLoad;
  }, [onLoad]);

  const imgElementAttrs: Omit<ImageElementProps, 'imgData'> = {
    isLazy,
    src,
    sizes,
    srcSet: overrideSrcSet,
    inlineStyle: showAltText ? { '--altColor': color('neutral900'), ...inlineStyle } : inlineStyle,
    styleExtend,
    loading,
    width,
    height,
    onLoadingCompleteRef,
    setShowAltText,
    ...rest,
  };

  if (priority) imgElementAttrs.fetchPriority = 'high';

  return (
    <>
      <ImageElement {...discardFields(imgElementAttrs, resetArgs)} />
      {
        // Note how we omit the `href` attribute, as it would only be relevant
        // for browsers that do not support `imagesrcset`, and in those cases
        // it would likely cause the incorrect image to be preloaded.
        //
        // https://html.spec.whatwg.org/multipage/semantics.html#attr-link-imagesrcset
        priority ? (
          <Head>
            <link
              key={`__nimg-${src}${overrideSrcSet}${sizes}`}
              rel="preload"
              as="image"
              href={overrideSrcSet ? undefined : src}
              {...linkProps}
            />
          </Head>
        ) : null
      }
    </>
  );
}
