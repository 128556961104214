'use client';

import useUpdateReadingHistory from '@haaretz/s-client-data-hooks/useUpdateReadingHistory';
import HtzLink, { HtzLinkProps } from '@haaretz/s-htz-link';
import isTeaserBiData from '@haaretz/s-type-predicates/isTeaserBiData';
import useBi from '@haaretz/s-use-bi';
import useImpressionObserver from '@haaretz/s-use-impression-observer';
import useToggleContext from '@haaretz/s-use-toggle-context';
import * as React from 'react';
import s9 from 'style9';

import type { StyleExtend, InlineStyles } from '@haaretz/s-types';
import type { BiFunction } from '@haaretz/s-use-bi/types';

// `c` is short for `classNames`
const c = s9.create({
  coverall: {
    backgroundColor: 'transparent',
    bottom: '0',
    left: '0',
    position: 'absolute',
    right: '0',
    top: '0',
    zIndex: '0',
  },
});

type SelectedHtzLinkProps = Omit<HtzLinkProps, 'className' | 'style'>;

export type BlockLinkProps = {
  children?: React.ReactNode;
  /**
   * CSS declarations to be set as inline `style` on the
   * html element.
   *
   * By setting values of CSS Custom Properties based on
   * props or state in the consuming component (where
   * the value of `inlineStyle` is passed), `inlineStyle`
   * can be used as an API contract for setting dynamic
   * values to styles created with `style9.create()`:
   *
   * @example
   * ```ts
   * import s9 from 'style9';
   * const { styleExtend, } = s9.create({
   *   styleExtend: {
   *     color: 'var(--color-based-on-prop)',
   *   },
   * });
   *
   * function MyBlockLink(props) {
   *   const inlineStyle = {
   *     '--color-based-on-prop': props.color,
   *   },
   *
   *   return (
   *    <BlockLink
   *      styleExtend={[ styleExtend, ]}
   *      inlineStyle={inlineStyle}
   *    />
   *   );
   * }
   * ```
   */
  inlineStyle?: InlineStyles;
  /**
   * An array of `Style`s created by `style9.create()`.
   * WARNING: **_do not_** pass simple CSS-in-JS object.
   * The items in the array must be created with Style9's
   * `create` function.
   * The array can also hold falsy values to assist with
   * conditional inclusion of `Style`s:
   *
   * @example
   * ```ts
   * const { foo, bar, } = s9.create({ foo: { ... }, bar: { ... }, });
   * <BlockLink styleExtend={[ someCondition && foo, bar, ]} />
   * ```
   */
  styleExtend?: StyleExtend;
  biData?: Parameters<BiFunction>['0'];
  coverRef?: React.Ref<HTMLElement>;
} & SelectedHtzLinkProps;

const BlockLinkInner = React.forwardRef(function BlockLinkInner(
  {
    children = null,
    inlineStyle,
    styleExtend = [],
    biData,
    onClick,
    coverRef,
    ...htzLinkProps
  }: Omit<BlockLinkProps, 'withImpression'>,
  ref: React.Ref<HTMLAnchorElement>
) {
  const biAction = useBi();

  const handleBiClick =
    biData || onClick
      ? (evt: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
          if (biData) biAction(biData);
          if (typeof onClick === 'function') onClick(evt);
        }
      : undefined;

  return (
    <HtzLink
      {...htzLinkProps}
      className={s9(...styleExtend)}
      style={inlineStyle}
      onClick={handleBiClick}
      ref={ref}
    >
      <span className={s9(c.coverall)} ref={coverRef} />
      {children}
    </HtzLink>
  );
});

const BlockLink = React.forwardRef(function BlockLink(
  { children = null, biData, ...htzLinkProps }: BlockLinkProps,
  ref: React.Ref<HTMLAnchorElement>
) {
  const coverRef = React.useRef<HTMLAnchorElement | null>(null);

  return (
    <>
      {biData ? <BlockLinkObserver elementRef={coverRef} {...biData} /> : null}
      <BlockLinkInner {...htzLinkProps} biData={biData} ref={ref} coverRef={coverRef}>
        {children}
      </BlockLinkInner>
    </>
  );
});

export default BlockLink;
const observerConfig = { threshold: 0.6 };

function BlockLinkObserver({
  elementRef,
  ...biData
}: Parameters<BiFunction>[0] & { elementRef: React.RefObject<HTMLElement> }) {
  const shouldPrevent = useToggleContext();

  const viewedArticleIds = isTeaserBiData(biData)
    ? biData.teaser.contentId
    : biData?.next_article_id;

  const { refetch: updateHistory } = useUpdateReadingHistory({
    variables: { viewedArticleIds },
    inView: true,
    clientOptions: { enabled: false },
  });

  useImpressionObserver({
    biData,
    disabled: false,
    elementRef,
    config: observerConfig,
    onObserve: () => {
      if (!shouldPrevent) {
        updateHistory();
      }
    },
  });
  return null;
}
