'use client';

import config from '@haaretz/l-config';
import Button from '@haaretz/s-button';
import useBi from '@haaretz/s-use-bi';
import * as React from 'react';

import type { BiDataOverrides } from '@haaretz/s-data-structure-types';
import type { StyleExtend } from '@haaretz/s-types';

export interface FomoTooltipButtonProps {
  biData: BiDataOverrides;
  text: string;
  styleExtend?: StyleExtend;
}

export default function FomoTooltipButton({
  biData,
  text,
  styleExtend = [],
}: FomoTooltipButtonProps) {
  const biAction = useBi('action');
  const {
    feature,
    feature_type = 'Marketing',
    campaign_name = 'fomo',
    campaign_details,
    action_id = 3,
  } = biData;

  const htmContent = campaign_details?.replace(/\s+/g, '-').toLowerCase();

  const promotionUrl = config.get('promotionUrl');
  const promotionsLink = `${promotionUrl}?htm_source=site&htm_medium=banner&htm_campaign=fomo&htm_campaign_type=subscription&htm_content=${htmContent}`;

  const onClick = () =>
    biAction({
      feature,
      feature_type,
      campaign_name,
      campaign_details,
      action_id,
    });

  return (
    <Button
      as="a"
      variant="sales"
      priority="primary"
      styleExtend={styleExtend}
      href={promotionsLink}
      onClick={onClick}
    >
      {text}
    </Button>
  );
}
